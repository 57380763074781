<template>
  <div class="base-info">
    <!-- <div class="base-info-status" v-if="info.status === 0">
      <img :src="waitImg" />
      <span>检测中，请耐心等待</span>
    </div> -->
    <div class="base-info-status">
      <img :src="successImg" />
      <span>检测完成</span>
      <!-- <span>{{ checkOrderState.getValueByKey(info.status) }}</span> -->
    </div>
    <div class="base-info-name">{{ info.clinicName }}</div>
    <div class="base-info-id">单号：{{ info.checkCode }}</div>
    <div class="base-info-warp">
      <div class="base-info-item">
        <span>姓名：</span>
        <span>{{ info.patientName }}</span>
        <!-- <template v-if="info.patientPhone">{{ info.patientPhone }}</template> -->
      </div>
      <div class="base-info-item">
        <span>性别：</span>
        <span>{{ genderType.getValueByKey(info.gender) }}</span>
      </div>
      <div class="base-info-item">
        <span>年龄：</span>
        <span v-if="info.age">{{ info.age }} 岁</span>
        <span v-else-if="info.ageMonth">{{ info.ageMonth }} 月龄</span>
        <span v-else>-</span>
      </div>
      <div class="base-info-item">
        <span>身高：</span>
        <span>{{ info.height || '-' }} cm</span>
      </div>
      <div class="base-info-item">
        <span>体重：</span>
        <span>{{ info.weight || '-' }} kg</span>
      </div>
      <div class="base-info-item report-warp" v-if="detail.signUrl">
        <span>签章图片：</span>
        <span @click="showImg(detail.signUrl)" class="sign-btn">点击查看</span>
        <template v-if="!isWeChat">
          <span class="line"> | </span>
          <span @click="toPrint(detail.signUrl)" class="sign-btn">点击下载</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { genderType } from '@/enum';
import { ImagePreview } from 'vant';
import { isWeChat } from '@/global';

// import emptyImg from '@/assets/img/无数据@2x.png';

export default {
  component: {
    ImagePreview
  },
  data() {
    return {
      isWeChat,
      genderType,
      successImg: this.$getImg('完成'),
      waitImg: this.$getImg('等待'),
      searchVal: '',
      notFoundImg: require('@/assets/img/404切图@2x.png')
    };
  },
  props: {
    detail: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(['checkOrderState']),
    info() {
      return this.detail.checkOrderVo;
    }
  },
  methods: {
    showImg(src) {
      this.$router.push({
        name: 'clinicSignPdf',
        query: {
          src
        }
      });
    },
    toPrint(src) {
      window.open(src);
    }
  }
};
</script>

<style lang="scss" scoped>
.base-info {
  padding: $tb $lr;
  background: #fff;
  .base-info-status {
    @include flex(row);
    align-items: center;
    img {
      width: 30px;
      margin-right: $lr;
    }
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .base-info-name {
    padding: $tb 0 0;
    font-size: $size5;
    color: $black1;
    font-weight: 600;
  }
  .base-info-id {
    padding: $tb 0;
    font-size: $size5;
    color: $gray2;
    font-weight: 600;
  }
  .base-info-warp {
    font-size: $size4;
    color: $black1;
    border-top: 1px dashed $gray3;
    border-bottom: 1px dashed $gray3;
    padding: $tb 0;
    .base-info-item {
      line-height: 30px;
      :first-child {
        color: $gray2;
      }

      .line {
        margin: 0 10px;
        position: relative;
        top: -1px;
      }
    }

    .report-warp {
      cursor: pointer;
    }

    .sign-btn {
      color: $appcolor;
    }
  }
}
</style>
