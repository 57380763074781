<template>
  <div class="info-table">
    <div class="info-table-div">
      <div
        class="info-table-item"
        v-for="(item, index) in panelList"
        :key="index"
        :style="{ width: item.width }"
        @click="toLink(item)"
      >
        <div class="info-table-title">{{ item.label }}</div>
        <div class="info-table-content">
          <div class="info-table-num">{{ judgmentType(info, item.key).num }}</div>
          <div class="info-table-calc-num" v-html="judgmentType(info, item.key).calcNum"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      panelList: [
        {
          label: 'C级合伙人',
          key: 'cPartnerCount'
        },
        {
          label: 'B级合伙人',
          key: 'bPartnerCount'
        },
        {
          label: '业务员',
          key: 'salesCount'
        },
        {
          label: '医生',
          key: 'doctorCount'
        },
        {
          label: '诊所',
          key: 'clinicCount'
        },
        {
          label: '服务单量',
          key: 'servicsCount'
        },
        {
          label: '设备签约量',
          key: 'goodsSignCount'
        },
        {
          label: '检测失败量',
          key: 'failedCheckCount'
        },
        {
          label: '检测量',
          key: 'checkCount',
          width: '50%',
          url: `/admin-check-order`
        },
        {
          label: '已收金额',
          key: 'chargeAmount',
          width: '50%'
        }
      ]
    };
  },
  props: {
    info: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    toLink(item) {
      if (!item.url) return;
      this.$router.push({
        path: item.url
      });
    },
    judgmentType(item, key) {
      let type = '';
      let calcNum = '';
      const num = item[key] - item.compareDataPanel[key];
      if (num > 0) {
        type = 'plus';
        calcNum = `<span style="color: #fe271b; font-weight: 600">+ ${num}</span>`;
      } else if (num < 0) {
        type = 'minus';
        calcNum = `<span style="color: #ffb811; font-weight: 600">- ${num}</span>`;
      }
      return {
        type,
        num: item[key],
        calcNum
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$borderColor: #e0edff;
.info-table {
  background: #ffffff;
  // padding: $tb 0;
  .info-table-div {
    @include flex(row);
    border-bottom: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    .info-table-item {
      box-sizing: border-box;
      width: 25%;
      text-align: center;
      border: 1px solid $borderColor;
      border-bottom: 0;
      border-right: 0;
      .info-table-title {
        background: #f3f9ff;
        padding: 14px 0;
        font-size: 12px;
        font-weight: 600;
      }
      .info-table-content {
        position: relative;
        padding: 20px 0;
        .info-table-num {
        }
        .info-table-calc-num {
          font-size: 14px;
          bottom: 4px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .plus {
    color: red;
    font-size: 16px;
  }
  .minus {
    color: #439eff;
    font-size: 16px;
  }
}
</style>
