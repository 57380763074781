<template>
  <div class="info-table">
    <div class="info-table-div">
      <div>
        <div class="info-table-cell">
          <div class="td td1 title">项目名称</div>
          <div class="td td2 title">检测结果</div>
          <div class="td td3 title">单位</div>
          <div class="td td3 title">参考范围</div>
        </div>
      </div>
      <div v-for="(item, index) in tableInfo" :key="index">
        <div class="info-table-cell">
          <div class="td td1">{{ showName(item) }}</div>
          <div class="td td2">
            {{ item.paramValue || '-' }}
            <i class="iconfont icon-up" v-if="item.flag === 'Up'" />
            <i class="iconfont icon-down" v-if="item.flag === 'Down'" />
          </div>
          <div class="td td3">{{ item.unit }}</div>
          <div class="td td3">{{ item.rangeLow }} ~ {{ item.rangeHigh }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    tableInfo: {
      type: Array,
      default: function() {
        return [
          // {
          //     name: '白细胞（WBC）',
          //     result: '8.82',
          //     unit: '10^9/L',
          //     range: '3.5~9.5'
          //  }
        ];
      }
    }
  },
  methods: {
    showName(item) {
      if (item.paramName === '-') {
        return item.nameKey;
      }
      return item.paramName;
    }
  }
};
</script>

<style lang="scss" scoped>
$borderColor: #e0edff;
.info-table {
  background: #ffffff;
  padding: $tb 0;
  .info-table-div {
    border: 1px solid $borderColor;
    border-right: 0;
    border-bottom: 0;
    margin: 0 $lr;
  }
  .info-table-cell {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: $size6;
    color: #330e0e;
    text-align: center;
  }
  .td {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-bottom: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    line-height: 18px;
  }

  .td1 {
    width: 30%;
    background: #f3f9ff;
  }
  .td2 {
    width: 30%;
  }
  .td3 {
    width: 20%;
  }

  .title {
    text-align: center;
    background: #f3f9ff;
    font-weight: 600;
    font-size: $size6;
  }
  .info-table-content {
    display: inline-block;
  }
  .info-table-edit {
    font-size: 14px;
    font-weight: 400;
    color: #3f6efc;
    margin-left: 10px;
  }

  .down-icon {
    color: red;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
  }
  .down-icon {
    color: blue;
    font-size: 14px;
    font-weight: 500;
  }
  .icon-up {
    color: red;
    font-size: 16px;
  }
  .icon-down {
    color: #439eff;
    font-size: 16px;
  }
}
</style>
