<template>
  <div>
    <setClinic @refresh="refreshList" ref="setClinic" :initShow="initShow" v-if="pageInit" />
    <pull-refresh v-model="pullLoading" @refresh="refreshList" :disabled="!pageInit">
      <div class="home-page">
        <div class="ach-panel">
          <p class="line-title">业绩</p>
          <div class="ach-warp">
            <!-- <div class="ach-header">
              <p class="ach-title">当日销量</p>
              <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.todaySalseAmount" /></p>
            </div> -->
            <div class="ach-header">
              <div>
                <p class="ach-title">当日</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.todaySalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">当月</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.cmSalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">上月</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.lmSalseAmount" /></p>
              </div>
            </div>
            <!-- <div class="ach-content">
              <div>
                <p class="ach-title">当月销量</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.cmSalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">上月销量</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.lmSalseAmount" /></p>
              </div>
            </div> -->
          </div>
        </div>
        <div class="func-warp">
          <div class="func-item" @click="toLink('clinicDetection')">
            <i class="iconfont icon-jc" />
            <span>申请检测</span>
          </div>
          <div class="func-item" @click="toLink('scanQRCode')" v-if="clinicId === 1">
            <i class="iconfont icon-sm" />
            <span>扫码检测</span>
          </div>
          <div class="func-item" @click="toLink('clinicBuy')">
            <i class="iconfont icon-cg" />
            <span>我要采购</span>
          </div>
        </div>
        <div class="report-panel">
          <p class="line-title">报告</p>
          <div class="report-warp">
            <reportIBox v-for="(item, index) in listInfo" :key="index" :info="item" />
            <empty v-if="!listInfo.length" description="暂无数据" />
          </div>
          <span class="read-more" v-if="listInfo.length" @click="toMore">更多<icon name="arrow"/></span>
        </div>
        <div class="warning-panel">
          <p class="line-title">预警</p>
          <div class="horizontal-scroll">
            <empty v-if="!stockList.length" description="暂无数据" />
            <div class="warning-warp">
              <div class="warning-item" v-for="(item, index) in stockList" :key="index">
                <div class="warning-name">{{ item.goodsName }}</div>
                <div><span>库存：</span>{{ item.stock }}</div>
                <div><span>有效期：</span>{{ item.serviceLimitDate }}</div>
                <i
                  class="iconfont icon-yujing"
                  style="color: #ffb811"
                  v-if="item.stockAlarmLevel === 1 || item.timeAlarmLevel === 1"
                />
                <i
                  class="iconfont icon-yujing"
                  style="color: #fe271b"
                  v-if="item.stockAlarmLevel === 2 || item.timeAlarmLevel === 2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 100px"></div>
    </pull-refresh>

    <earlyWarning v-if="earlyWarningModel.show" :show.sync="earlyWarningModel.show" :info="earlyWarningModel.info" />

    <tabbar :curIndex="0" />
  </div>
</template>

<script>
import services from '@/service';
import countTo from 'vue-count-to';

import tabbar from '../components/tabbar';
import setClinic from './components/setClinic';
import { PullRefresh, Icon, Empty } from 'vant';
import { mapGetters } from 'vuex';
import reportIBox from '@/components/reportIBox';
import { scanQRCode, routerPush, notRequest } from '@/lib/utils';
import earlyWarning from '@/components/earlyWarning';
import dayjs from 'dayjs';
import { getUserType } from '@/lib/utils';

export default {
  components: { earlyWarning, setClinic, countTo, tabbar, PullRefresh, Icon, Empty, reportIBox },
  data() {
    return {
      earlyWarningModel: {
        show: false,
        info: {}
      },
      pullLoading: false,
      scoreInfo: {
        todaySalseAmount: 0,
        cmSalseAmount: 0,
        lmSalseAmount: 0
      },
      searchVal: '',
      listInfo: [],
      stockList: [],
      initShow: false
    };
  },
  mounted() {
    if (!this.pageInit) return;
    this.refreshList();
  },
  computed: {
    ...mapGetters(['token', 'clinicId']),
    pageInit() {
      return !notRequest();
    },
    userType() {
      return getUserType();
    }
  },
  methods: {
    refreshList() {
      this.pullLoading = true;
      setTimeout(async () => {
        await Promise.all([
          this.getClinicScore(),
          this.getOrderList(),
          this.getClinicDeviceStock(),
          this.getClinicStockAlarm()
        ]);
        this.pullLoading = false;
      }, 1000);
    },
    async getClinicStockAlarm() {
      const storageKey = `${getUserType()}_stockAlarm`;
      const today = dayjs().format('YYYY-MM-DD');
      const storageStockAlarm = localStorage.getItem(storageKey);

      const { data } = await services.clinic.getClinicStockAlarm(this.clinicId);
      if (data.stockAlarmLevel || data.timeAlarmLevel) {
        if (`${this.clinicId}_${today}` === storageStockAlarm) return;
        localStorage.setItem(storageKey, `${this.clinicId}_${today}`);

        this.earlyWarningModel.info = data;
        this.earlyWarningModel.show = true;
      } else {
        localStorage.removeItem(storageKey);
      }
    },
    async getClinicScore() {
      const res = await services.clinic.getClinicScore();
      this.scoreInfo = res.data;
    },
    async getOrderList() {
      const res = await services.clinic.checkOrderList({ page: 1, size: 2, clinicId: this.clinicId });
      this.listInfo = res.rows.splice(0, 2);
    },
    async getClinicDeviceStock() {
      const res = await services.clinic.getClinicDeviceStock(this.clinicId);
      // this.stockList = res.data.sort((a, b) => a.stock - b.stock);
      this.stockList = res.data;
    },
    async toLink(name) {
      if (name === 'scanQRCode') {
        //扫码
        const deviceNum = await scanQRCode();
        routerPush({
          name: 'clinicDetection',
          query: {
            deviceNum
          }
        });
      } else {
        routerPush({
          name
        });
      }
    },
    toMore() {
      routerPush({
        name: 'clinicDetectionManagement'
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async _this => {
      if (from.name == 'clinicLogin') {
        _this.initShow = true;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-empty {
    padding: 0;
    .van-empty__image {
      width: 60px;
      height: 60px;
    }
  }
}
.home-page {
  .line-title {
    margin: $tb $lr 0px;
  }
  // padding: $tb $lr;
  .ach-panel {
    .ach-warp {
      margin: $tb $lr;
      box-shadow: $box-shadow1;
      border-radius: $radius;
      overflow: hidden;
      background: #fff;
      color: #fff;

      .ach-header {
        background: $btnbg;
        padding: $tb $lr;
        display: flex;
        justify-content: space-around;
        > div {
          flex: 1;
          text-align: center;
        }
        .ach-title {
          font-size: $size4;
        }
        .ach-num {
          font-size: 34px;
          padding-top: $tb;
        }
      }

      .ach-content {
        display: flex;
        justify-content: space-around;
        padding: $tb $lr;
        > div {
          flex: 1;
          text-align: center;
          border-right: 2px solid $bordercolor;
          &:last-child {
            border-right: none;
          }
        }
        .ach-title {
          color: $gray2;
          font-size: $size5;
        }
        .ach-num {
          font-size: 16px;
          padding-top: $tb;
          text-align: center;
          color: $black1;
        }
      }
      // > div {
      //   text-align: center;
      //   .ach-title {
      //     font-size: $size4;
      //     color: $gray2;
      //     margin-bottom: $tb;
      //     font-weight: 600;
      //   }
      //   .ach-num {
      //     font-size: 24px;
      //     font-weight: 600;
      //     color: $black1;
      //   }
      // }
    }
  }

  .report-panel {
    margin-top: 40px;
    position: relative;
    .read-more {
      position: absolute;
      right: $lr;
      top: 0px;
      font-size: $size5;
      color: $gray2;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .warning-panel {
    margin-top: 40px;

    .horizontal-scroll {
      overflow: auto;
      white-space: nowrap;
    }
    .warning-warp {
      display: flex;
      flex-wrap: nowrap;
      padding: 20px $lr $tb;
      overflow-x: scroll;
      .warning-item {
        // width: 100px;
        flex-shrink: 0; /* 防止内容收缩 */
        background: #fff;

        margin-right: $lr;
        border-radius: $radius;
        box-shadow: $box-shadow1;
        position: relative;
        padding: $tb $lr 0;
        font-size: $size5;

        .warning-name {
          font-size: $size3;
          font-weight: 500;
        }

        > div {
          margin-bottom: $tb;
          span {
            font-size: $size5;
            color: $gray2;
          }
        }

        i {
          position: absolute;
          right: 4px;
          top: -18px;
          font-size: 20px;

          /* background-color: $btnNone; */
          color: transparent; /* 将iconfont的颜色改为透明 */
          -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
          background-image: -webkit-linear-gradient(#ff6801, #ff2c00);
        }
      }
    }
  }

  .func-warp {
    margin: $tb $lr 0;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    padding: $tb $lr;
    background: #fff;
    @include flex(row);
    justify-content: space-around;
    .func-item {
      @include flexCenter(column);
      font-size: $size5;
      color: $gray2;
      width: 30%;
      // margin-bottom: $tb;
      i {
        font-size: 30px;
        margin-bottom: 6px;
      }
    }
    .func-item i {
      color: transparent;
      -webkit-background-clip: text;
      background-image: $btnbg;
    }
  }
}
</style>
