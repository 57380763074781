import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import FastClick from 'fastclick';
import plugins from './lib/plugins';
import { initRouterController } from './router/globalController';
import { Notify, Toast, Dialog, Loading, Lazyload } from 'vant';
import '@vant/touch-emulator';
import './lib/rem';
import reporter from './lib/reporter';
import wechat from '@/wechat';
import { genderType } from '@/enum';

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);
Vue.use(Toast);
Vue.use(Notify);
Vue.use(Dialog);

Vue.config.productionTip = false;

// 引入后首次点击有问题
// FastClick.attach(document.body);

Vue.use(plugins);
Vue.use(Loading);
Vue.use(Lazyload);

Vue.config.errorHandler = function(err) {
  reporter.log({ message: err.stack || err.message });
  console.error(err);
};

Vue.prototype.$getImg = function(name, type = 'png') {
  return require(`@/assets/img/${name}.${type}`);
};

Vue.prototype.$getGender = function(gender) {
  return genderType.getValueByKey(gender);
};

Vue.config.ignoredElements = ['wx-open-launch-weapp'];

Vue.prototype.$error = function(options) {
  console.error(options);
  let showMsg = true;
  let isRequestErr = false;
  let errMsg = '';
  let errStack = '';
  if (typeof options === 'string') {
    errMsg = options;
  } else {
    isRequestErr = !!options.code;
    errMsg = options.message || '此处报错';
    errStack = options.stack;
  }

  const reportInfo = { message: errStack || errMsg };
  if (isRequestErr) {
    reportInfo.code = options.code;
    reportInfo.requestInfo = options.requestInfo;
  }
  reporter.log(reportInfo);

  if (errMsg.includes('TypeError: Cannot read properties of undefined')) {
    showMsg = false;
  }
  if (errMsg.includes('Cannot read properties of null (reading')) {
    showMsg = false;
  }
  if (errMsg.includes('Network Error')) {
    showMsg = false;
  }

  if (showMsg) {
    Notify({ type: 'danger', message: errMsg });
  }
};

const renderAppHandle = async () => {
  wechat.init();

  initRouterController();

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
};

renderAppHandle();

// function directRightUrl() {
//   let { href, protocol, host, pathname, search, hash } = window.location;

//   // 兼容微信支付无法识别url
//   search = search || '?';
//   const newHref = `${protocol}//${host}${pathname}${search}${hash}`;
//   console.log('href', href);
//   console.log('newHref', newHref);
//   if (newHref !== href) {
//     window.location.replace(newHref);
//     return;
//   }

//   renderAppHandle();
// }
// directRightUrl();
