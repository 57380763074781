var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "detection" }, [
        _c("p", { staticClass: "line-title" }, [_vm._v("患者信息")]),
        _c(
          "div",
          { staticClass: "detection-form" },
          [
            _c(
              "van-form",
              { ref: "formRef" },
              [
                _c("field", {
                  ref: "patientPhone",
                  attrs: {
                    name: "患者手机",
                    label: "患者手机：",
                    placeholder: "请输入患者手机",
                    type: "number",
                    maxLength: "11",
                  },
                  on: { input: _vm.getPatientRelByPhone },
                  model: {
                    value: _vm.formInfo.patientPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "patientPhone", $$v)
                    },
                    expression: "formInfo.patientPhone",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "患者姓名",
                    label: "患者姓名：",
                    placeholder: "请输入患者姓名",
                    rules: [{ required: true, message: "请输入患者姓名" }],
                    required: "",
                  },
                  model: {
                    value: _vm.formInfo.patientName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "patientName", $$v)
                    },
                    expression: "formInfo.patientName",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "年龄",
                    label: "年龄：",
                    placeholder: "请输入年龄",
                    type: "number",
                    rules: [
                      {
                        required: true,
                        pattern: /^([1-9]\d*)$/,
                        message: "请输入整数年龄",
                      },
                    ],
                    maxLength: "3",
                    required: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button",
                      fn: function () {
                        return [
                          _c(
                            "radio-group",
                            {
                              attrs: { direction: "horizontal" },
                              on: { change: _vm.changeAgeType },
                              model: {
                                value: _vm.formInfo.ageType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInfo, "ageType", $$v)
                                },
                                expression: "formInfo.ageType",
                              },
                            },
                            [
                              _c("radio", { attrs: { name: "age" } }, [
                                _vm._v("岁"),
                              ]),
                              _c("radio", { attrs: { name: "ageMonth" } }, [
                                _vm._v("月"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.formInfo[_vm.formInfo.ageType],
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, _vm.formInfo.ageType, $$v)
                    },
                    expression: "formInfo[formInfo.ageType]",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "radio",
                    label: "性别：",
                    required: "",
                    placeholder: "请选择性别",
                    rules: [{ required: true, message: "请选择性别" }],
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c(
                            "radio-group",
                            {
                              attrs: { direction: "horizontal" },
                              model: {
                                value: _vm.formInfo.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInfo, "gender", $$v)
                                },
                                expression: "formInfo.gender",
                              },
                            },
                            [
                              _c("radio", { attrs: { name: "0" } }, [
                                _vm._v("男"),
                              ]),
                              _c("radio", { attrs: { name: "1" } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("field", {
                  attrs: {
                    name: "身高",
                    label: "身高：",
                    placeholder: "请输入身高，单位CM",
                    type: "number",
                  },
                  model: {
                    value: _vm.formInfo.height,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "height", $$v)
                    },
                    expression: "formInfo.height",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "体重",
                    label: "体重：",
                    placeholder: "请输入体重，单位KG",
                    type: "number",
                  },
                  model: {
                    value: _vm.formInfo.weight,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "weight", $$v)
                    },
                    expression: "formInfo.weight",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { height: "10px" } }),
        _c("p", { staticClass: "line-title" }, [_vm._v("检测项目")]),
        _c(
          "div",
          { staticClass: "project-form" },
          [
            _c(
              "checkbox-group",
              {
                model: {
                  value: _vm.projectSelect,
                  callback: function ($$v) {
                    _vm.projectSelect = $$v
                  },
                  expression: "projectSelect",
                },
              },
              _vm._l(_vm.projectList, function (item, index) {
                return _c(
                  "checkbox",
                  {
                    key: index,
                    staticClass: "group-checkbox",
                    attrs: {
                      disabled: item.stock <= 0,
                      name: item.goodsMealId,
                      shape: "square",
                    },
                  },
                  [
                    _c("div", { staticClass: "group-checkbox-content" }, [
                      _c("span", { staticClass: "goods-name" }, [
                        _vm._v(_vm._s(item.goodsName)),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "stock",
                          style: { color: _vm.dateColor(item.stockAlarmLevel) },
                        },
                        [_vm._v("余：" + _vm._s(item.stock))]
                      ),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "goods-foot-warp",
                        style: { color: _vm.dateColor(item.timeAlarmLevel) },
                      },
                      [
                        _c("span", { staticClass: "goods-date" }, [
                          _vm._v("有效期：" + _vm._s(item.serviceLimitDate)),
                        ]),
                        item.paymentMode === _vm.paymentModeMap.patient
                          ? _c("span", { staticClass: "goods-price" }, [
                              _c("span", [_vm._v("￥")]),
                              _vm._v(_vm._s(item.salePrice)),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm.projectSelect.includes(item.goodsMealId) &&
                    _vm.deviceListComp(item.goodsSettingVoList).length > 1
                      ? _c(
                          "div",
                          { staticClass: "device-warp" },
                          [
                            _c(
                              "radio-group",
                              {
                                model: {
                                  value: item.deviceNum,
                                  callback: function ($$v) {
                                    _vm.$set(item, "deviceNum", $$v)
                                  },
                                  expression: "item.deviceNum",
                                },
                              },
                              [
                                _c(
                                  "cell-group",
                                  _vm._l(
                                    _vm.deviceListComp(item.goodsSettingVoList),
                                    function (dItem, dIndex) {
                                      return _c("cell", {
                                        key: "d_" + dIndex,
                                        attrs: { title: dItem.deviceNum },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            item.deviceNum = dItem.deviceNum
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "right-icon",
                                              fn: function () {
                                                return [
                                                  _c("radio", {
                                                    attrs: {
                                                      name:
                                                        dItem.deviceName ||
                                                        dItem.deviceNum,
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("action-sheet", {
        attrs: {
          actions: _vm.actions,
          "cancel-text": "取消",
          "close-on-click-action": "",
        },
        on: { select: _vm.selectPatient },
        model: {
          value: _vm.actionShow,
          callback: function ($$v) {
            _vm.actionShow = $$v
          },
          expression: "actionShow",
        },
      }),
      _vm.qrcodeModel.show
        ? _c("qrcode", {
            attrs: { show: _vm.qrcodeModel.show, info: _vm.qrcodeModel.info },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.qrcodeModel, "show", $event)
              },
            },
          })
        : _vm._e(),
      _c("div", { staticStyle: { height: "200px" } }),
      _c("fixedButton", {
        attrs: { text: "提交申请" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }