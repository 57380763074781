const patientLogin = () => import(/* webpackChunkName: "patient" */ '@/views/patient/login');
const patientHome = () => import(/* webpackChunkName: "patient" */ '@/views/patient/home');
const patientPersonal = () => import(/* webpackChunkName: "patient" */ '@/views/patient/personal');
const patientClinicDetail = () => import(/* webpackChunkName: "patient" */ '@/views/patient/clinicDetail');
const patientInfoEdit = () => import(/* webpackChunkName: "patient" */ '@/views/patient/infoEdit');
const patientReportDetail = () => import(/* webpackChunkName: "patient" */ '@/views/patient/reportDetail');
const patientReportPay = () => import(/* webpackChunkName: "patient" */ '@/views/patient/reportPay');

export default [
  {
    path: '/patient-login',
    name: 'patientLogin',
    component: patientLogin,
    meta: { title: '登录', hideBack: true, noAuth: true }
  },
  {
    path: '/patient-home',
    name: 'patientHome',
    component: patientHome,
    meta: { title: '首页', hideBack: true }
  },
  {
    path: '/patient-personal',
    name: 'patientPersonal',
    component: patientPersonal,
    meta: {
      title: '我的',
      hideBack: true,
      keeps: ['subjectAnalyse', 'subjectLawerdetail']
    }
  },
  {
    path: '/patient-clinic-detail',
    name: 'patientClinicDetail',
    component: patientClinicDetail,
    meta: {
      title: '诊所详情'
    }
  },
  {
    path: '/patient-info-edit',
    name: 'patientInfoEdit',
    component: patientInfoEdit,
    meta: {
      title: '基本信息'
    }
  },
  {
    path: '/patient-report-detail',
    name: 'patientReportDetail',
    component: patientReportDetail,
    meta: {
      title: '报告详情'
    }
  },

  {
    path: '/patient-report-pay',
    name: 'patientReportPay',
    component: patientReportPay,
    meta: {
      title: '报告详情'
    }
  }
];
