import enumFactory from './enumFactory';
import * as api from './enum';
// import {genderListAll, partnerLevelListAll} from "./enum";

// 产品列表
export const settingListAll = async () => {
  const res = await api.settingListAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.goodsName,
      key: i.goodsSettingId
    };
  });
  return enumFactory(list);
};

// 公司仓库列表
export const compWarehouseAll = async () => {
  const res = await api.compWarehouseAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.compName,
      key: i.compId
    };
  });
  return enumFactory(list);
};

// 设备列表
export const settingDevicesAll = async () => {
  const res = await api.settingDevicesAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.goodsName,
      key: i.goodsSettingId
    };
  });
  return enumFactory(list);
};

// 耗材列表
export const consumablesAll = async () => {
  const res = await api.consumablesAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.goodsName,
      key: i.goodsSettingId
    };
  });
  return enumFactory(list);
};

// 诊所列表
export const clinicListAll = async () => {
  const res = await api.clinicListAll();
  const list = [
    { text: '全部诊所', value: '' },
    ...res.data.map(i => {
      return {
        ...i,
        text: i.clinicName,
        value: i.clinicId
      };
    })
  ];
  return enumFactory(list);
};

// 诊所列表 picker使用
export const clinicListAll2 = async () => {
  const res = await api.clinicListAll();
  const list = [
    { value: '全部诊所', key: '' },
    ...res.data.map(i => {
      return {
        ...i,
        value: i.clinicName,
        key: i.clinicId
      };
    })
  ];
  return enumFactory(list);
};

// 合伙人列表
export const partnerListAll = async () => {
  const res = await api.partnerListAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.name,
      key: i.id
    };
  });
  return enumFactory(list);
};

// 业务员列表
export const salesListAll = async () => {
  const res = await api.salesListAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.name,
      key: i.id
    };
  });
  return enumFactory(list);
};

// 快递列表
export const logisticsCompanyListAll = async () => {
  const res = await api.logisticsCompanyListAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.company,
      key: i.companyNumber
    };
  });
  return enumFactory(list);
};

// 订单状态列表
export const serviceOrderStateAll = async () => {
  const res = await api.serviceOrderStateAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 库存记录来源类型
export const stockRecordSrcAll = async () => {
  const res = await api.stockRecordSrcAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 库存记录类型(操作)
export const stockRecordTypeAll = async () => {
  const res = await api.stockRecordTypeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 分类
export const goodsSubTypeAll = async () => {
  const res = await api.goodsSubTypeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 诊所和医生状态
export const enableOrDisable = async () => {
  const res = await api.enableOrDisable();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 诊所审批状态和医生审批状态
export const auditStatusAll = async () => {
  const res = await api.auditStatusAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 校验模式
export const testModeAll = async () => {
  const res = await api.testModeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 检测订单状态
export const checkOrderStateAll = async () => {
  const res = await api.checkOrderStateAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 厂家
export const thirdCompanyAll = async () => {
  const res = await api.thirdCompanyAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.thirdCompName,
      key: i.thirdCompId
    };
  });
  return enumFactory(list);
};

// 套餐类型
export const packageTypeAll = enumFactory([
  {
    value: '服务',
    key: 'FW'
  },
  {
    value: '项目',
    key: 'XM'
  }
]);

// 产品类型
export const goodsTypeAll = enumFactory([
  {
    value: '设备',
    key: 'SB'
  },
  {
    value: '耗材',
    key: 'HC'
  }
]);

export const salesTypeAll = enumFactory([
  {
    value: '业务员',
    key: '0'
  },
  {
    value: '合伙人',
    key: '1'
  }
]);

// 合伙人完成度
export const partnerExceedFlag = enumFactory([
  {
    value: '完成',
    key: '0'
  },
  {
    value: '未完成',
    key: '1'
  }
]);

// 合伙人级别
export const partnerLevelAll = async () => {
  const res = await api.partnerLevelListAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.levelName,
      key: i.levelId
    };
  });
  return enumFactory(list);
};

// 出库状态
export const outFlagAll = enumFactory([
  {
    value: '是',
    key: '1'
  },
  {
    value: '否',
    key: '0'
  }
]);

// 产品、套餐、设备状态
export const defaultFlagAll = enumFactory([
  {
    value: '正常',
    key: '0'
  },
  {
    value: '停用',
    key: '1'
  }
]);

// 性别状态
export const genderListAll = async () => {
  const res = await api.genderListAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

/**
 * 用户角色类型
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const roleTypeAll = async () => {
  const res = await api.roleTypeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

/**
 * 站内信的消息类型
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const messageTypeAll = async () => {
  const res = await api.messageTypeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

/**
 * 站内信的消息分类
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const messageCateGoryAll = async () => {
  const res = await api.messageCateGoryAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 是否
export const yesOrNoFlagAll = enumFactory([
  {
    value: '是',
    key: '1'
  },
  {
    value: '否',
    key: '0'
  }
]);

/**
 * 意见反馈类型枚举
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const feedbackTypeAll = async () => {
  const res = await api.feedbackTypeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

/**
 * 紧急程度
 * @type {{enums: *[], getKeyByValue(*): any|string, keys: *[], values: *[], getValueByKey(*): any|string}|*}
 */
export const priorityAll = enumFactory([
  {
    value: '高',
    key: 1
  },
  {
    value: '中',
    key: 2
  },
  {
    value: '低',
    key: 3
  }
]);

/**
 * 短信模板CODE
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const smsTemplateEnumAll = async () => {
  const res = await api.smsTemplateEnumAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

/**
 * 成功失败状态枚举
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const successFailedStatusEnumAll = async () => {
  const res = await api.successFailedStatusEnumAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

/**
 * 是否异步标识枚举
 * @returns {Promise<{enums: *[], getKeyByValue(*): *|string, keys: *[], values: *[], getValueByKey(*): *|string}|*>}
 */
export const syncFlagStatusEnumAll = async () => {
  const res = await api.syncFlagStatusEnumAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

export const appIdAll = enumFactory([
  {
    value: 'i检医小程序',
    key: 'wx28b1ced1c60ec2aa'
  },
  // {
  //   value: 'i检云小程序',
  //   key: 'wxe189d905f7ff3533'
  // },
  // {
  //   value: 'i检康小程序',
  //   key: 'wx3e7c4f8dbf42d679'
  // }
  {
    value: '爱检康公众号',
    key: 'wx34e3db8542afcddb'
  }
]);

// 设备申请记录状态
export const deviceApplyAll = enumFactory([
  {
    value: '待审批',
    key: '0',
    color: '#ff976a'
  },
  {
    value: '审批不通过',
    key: '1',
    color: '#ff976a'
  },
  {
    value: '审批通过',
    key: '2',
    color: '#ff976a'
  },
  {
    value: '已发货',
    key: '4',
    color: '#ff976a'
  }
]);
