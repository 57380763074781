<template>
  <div>
    <fixedButton text="去支付" @emitEvent="createOrder" />

    <!-- <div class="btn" @click="createOrder">去支付</div>
    <div class="btn" @click="wxPay" v-if="orderCode">微信支付</div> -->
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
// import { Stepper, Image as VanImage, Icon, Field } from 'vant';
import { mapGetters } from 'vuex';
import { isWeChat, isMiniProgram } from '@/global';
import wechat from '@/wechat';
import pay from '@/lib/pay';

export default {
  components: { fixedButton },
  data() {
    return {
      address: {},
      products: [],
      timer: null,
      toCheck: false,
      orderInfo: {},
      orderCode: '',
      serviceOrderId: ''
    };
  },
  computed: {
    ...mapGetters(['openId', 'clinicInfo', 'addressInfo']),
    hasCartNum() {
      return this.products.reduce((sum, product) => sum + product.number, 0);
    },
    cartTotalPrice() {
      return this.products.reduce((sum, product) => sum + product.number * product.salePrice, 0) * 100;
    },
    code() {
      return this.$route.query.code;
    },
    hasOrder() {
      return this.orderCode;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async getOpenId() {
      if (isWeChat && !this.openId && this.code) {
        const res = await services.common.getOpenId({ authorizeCode: this.code });
        this.$store.dispatch('set_openId', res.data.openId);
      }
    },
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    onSubmit() {},
    selectAddr() {
      if (this.hasOrder) return;
      this.$router.push({
        name: 'clinicMyAddress',
        query: {
          isSelect: true
        }
      });
    },
    async createOrder() {
      this.$store.dispatch('addLoading');
      const params = {
        addressCityName: this.addressInfo.city,
        addressDetail: this.addressInfo.address,
        addressMobile: this.addressInfo.phone,
        addressName: this.addressInfo.addressee,
        addressProvinceName: this.addressInfo.province,
        addressRegionName: this.addressInfo.district,
        clinicId: this.clinicInfo.clinicId,
        clinicName: this.clinicInfo.clinicName,
        remark: this.orderInfo.remark,
        orderDetailVoList: this.products.map(i => {
          return {
            goodsMealId: i.goodsMealId,
            number: i.number
          };
        })
      };
      const orderRes = await services.clinic.createServiceOrder(params);
      this.orderCode = orderRes.data.orderCode;
      this.serviceOrderId = orderRes.data.serviceOrderId;
      this.$store.dispatch('subLoading');
      this.wxPay();
    },
    async wxPay() {
      if (isMiniProgram) {
        const data = {
          orderCode: this.orderCode,
          tradeType: 'JSAPI'
        };
        wechat.$wx.miniProgram.navigateTo({ url: `/pages/miniH5Pay/index?data=${JSON.stringify(data)}` });
        if (!this.toCheck) {
          this.checkOrderStatus();
        }
      } else {
        this.$store.dispatch('addLoading');
        const res = await services.clinic.wxPay({
          orderCode: this.orderCode,
          tradeType: 'JSAPI',
          openId: this.openId
        });
        this.$store.dispatch('subLoading');

        await pay(res.data);
        this.$toast('支付成功');
        setTimeout(() => {
          this.$router.replace({
            name: 'clinicMyOrder'
          });
        }, 1000);
      }
    },
    async getOrderDetail() {
      const res = await services.clinic.getServiceOrder(this.serviceOrderId);
      this.orderInfo = res.data;
    },
    checkOrderStatus() {
      this.toCheck = true;
      this.timer = setInterval(this.pollOrderStatus, 1000);
    },
    async pollOrderStatus() {
      await this.getOrderDetail();
      if (!['0', '2'].includes(this.orderInfo.status)) {
        clearInterval(this.timer);
        this.$toast('支付成功');
        this.$router.replace({
          name: 'clinicMyOrder'
        });
      }
    }
  },
  mounted() {
    console.log('window.location.href', window.location.href);
    console.log('openId:', this.openId);

    this.getOpenId();
  }
};
</script>

<style lang="scss" scoped>
.order-confirm {
  padding: $tb $lr;
}

.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  // margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.address-content {
  display: flex;
  align-items: center;

  .icon-shdz {
    font-size: 32px;
    margin-right: $lr;
    color: transparent; /* 将iconfont的颜色改为透明 */
    -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
    background-image: $btnbg;
  }
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.view-details {
  font-size: $size1;
  color: $appcolor;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  overflow: hidden;
}

.price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  padding: $tb $lr;
  font-size: $size3;
  color: $gray1;
  text-align: right;
  .price {
    color: $red1;
    font-weight: 600;
    margin-left: $lr;
  }
}
.product-item {
  flex: 1;
  border-bottom: 1px solid $bordercolor;
  padding: $tb 0;
  margin: 0 $lr;
  &:last-child {
    border: none;
  }
}

.product-content {
  display: flex;
  align-items: center;
}

.product-image {
  width: 100px;
  height: 100px;
  border-radius: 5px 0 0 5px;
  margin-right: $lr;
}

.product-details {
  flex: 1;
  .product-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: $lr;
  }
}

.product-name {
  color: $black1;
  margin-bottom: $tb;
  font-size: $size2;
}

.product-description {
  color: $gray2;
  margin-bottom: $tb;
  font-size: $size4;
  @include hiddenText();
}

.product-price {
  font-weight: 600;
  color: $black1;
}

.van-submit-bar {
  bottom: $tabbarHeight;
}
.cart-icon {
  font-size: 24px;
  &.active {
    color: $appcolor;
  }
}

.btn {
  margin: $tb $lr;
}
</style>
