<template>
  <div class="performance">
    <sticky>
      <p class="info-title">统计时间截止至 {{ changeDate(listInfo) }}</p>
      <search
        v-model="queryParams.clinicName"
        placeholder="请输入诊所名称，支持模糊搜索"
        @search="refreshList"
        @clear="refreshList"
      />
      <div class="info-table">
        <div class="info-table-div" style="border-bottom: 0;">
          <div>
            <div class="info-table-cell">
              <div class="td td1 title">
                诊所名称
              </div>
              <div class="td td2 title" @click="sortHandle('lmDailyAvg')">
                上月平均日单量
                <i class="iconfont icon-px" v-if="sortCur !== 'lmDailyAvg' || sortIndex === 0" />
                <i class="iconfont icon-jx" v-if="sortCur === 'lmDailyAvg' && sortIndex === 1" />
                <i class="iconfont icon-sx" v-if="sortCur === 'lmDailyAvg' && sortIndex === 2" />
              </div>
              <div class="td td2 title" @click="sortHandle('cmDailyAvg')">
                本月平均日单量
                <i class="iconfont icon-px" v-if="sortCur !== 'cmDailyAvg' || sortIndex === 0" />
                <i class="iconfont icon-jx" v-if="sortCur === 'cmDailyAvg' && sortIndex === 1" />
                <i class="iconfont icon-sx" v-if="sortCur === 'cmDailyAvg' && sortIndex === 2" />
              </div>
              <div class="td td3 title" @click="sortHandle('stockDayRatio')">
                库存 / 有效期
                <i class="iconfont icon-px" v-if="sortCur !== 'stockDayRatio' || sortIndex === 0" />
                <i class="iconfont icon-jx" v-if="sortCur === 'stockDayRatio' && sortIndex === 1" />
                <i class="iconfont icon-sx" v-if="sortCur === 'stockDayRatio' && sortIndex === 2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </sticky>
    <pull-refresh v-model="pullLoading" @refresh="refreshList" :disabled="!pageInit">
      <list
        v-if="listInfo.length"
        v-model="loading"
        :finished="!hasNext"
        finished-text="没有更多了"
        @load="loadList"
        :immediate-check="false"
      >
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="info-table">
          <div class="info-table-div" style="border-top: 0;">
            <div v-for="(item, index) in listInfo" :key="index">
              <div class="info-table-cell">
                <div class="td td1 clinic-name">{{ valueHandle(item.clinicName) }}</div>
                <div class="td td2">{{ valueHandle(item.lmDailyAvg) }}</div>
                <div class="td td2" v-html="stockDayRatioValueHandle(item)"></div>
                <div class="td td3">{{ valueHandle(item.stockAmount) }} / {{ valueHandle(item.residualDay) }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <clinicBox v-for="(item, index) in listInfo" :key="index" :info="item" :active="active" /> -->
      </list>
      <empty v-if="!listInfo.length" description="暂无数据" />
    </pull-refresh>

    <div style="height: 140px"></div>
    <tabbar :curIndex="1" v-if="!noSelf" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { Search, PullRefresh, List, Empty, Sticky } from 'vant';
import services from '@/service';
import tabbar from '../components/tabbar';
import { notRequest } from '@/lib/utils';

export default {
  components: { Search, PullRefresh, List, tabbar, Empty, Sticky },
  data() {
    return {
      sortName: [],
      sortCur: '',
      sortList: ['', 'descending', 'ascending'],
      sortIndex: 0,
      tabList: [
        {
          title: '我的诊所',
          func: 'getPerformanceList'
        },
        {
          title: '开发中',
          func: 'getDevelopClinicList'
        }
      ],
      active: 0,
      items: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        clinicName: '',
        pageNum: 0,
        pageSize: 20
      },
      listInfo: []
    };
  },
  computed: {
    pageInit() {
      return !notRequest();
    },
    noSelf() {
      return this.$route.query.id;
    }
  },
  created() {},
  async mounted() {
    if (!this.pageInit) return;

    if (this.$route.query.id) {
      this.queryParams.salesId = this.$route.query.id;
    }
    this.refreshList();
  },
  methods: {
    sortHandle(type) {
      if (type !== this.sortCur) {
        this.sortIndex = 0;
        this.sortCur = type;
      }
      this.sortIndex = (this.sortIndex + 1) % this.sortList.length;
      this.refreshList();
    },
    async refreshList() {
      if (!this.pageInit) return;

      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;
      console.log('this.active', this.active);

      const res = await services.partner.getPerformanceList({
        ...this.queryParams,
        isAsc: this.sortList[this.sortIndex],
        orderByColumn: this.sortCur
      });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDetail(item) {
      const routerType = ['partnerClinicInfo', 'partnerClinicInfoEdit'];
      this.$router.push({
        name: routerType[this.active],
        query: {
          id: item.clinicId,
          hisId: item.hisId
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    changeDate(listInfo) {
      if (!listInfo.length) return '-';
      return dayjs(listInfo[0].dd, 'YYYYMMDD').format('YYYY-MM-DD');
    },
    valueHandle(num) {
      return num === null ? '-' : num;
    },
    stockDayRatioValueHandle(item) {
      if (item.cmDailyAvg === null) return '-';
      if (item.cmDailyAvg < item.stockDayRatio) {
        return `<span style="color:red;">${item.cmDailyAvg}</span>`;
      } else {
        return item.cmDailyAvg;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.performance {
  background: #fff;
}

.icon-px {
  color: $gray2;
}

.icon-jx,
.icon-sx {
  color: $appcolor;
  font-weight: 600;
}

$borderColor: #e0edff;
.info-title {
  background: #fff;
  text-align: center;
  font-size: 14px;
  padding: 10px 0 0;
  color: $appcolor;
}

.info-table {
  background: #ffffff;
  // padding: $tb 0;
  .info-table-div {
    border: 1px solid $borderColor;
    border-right: 0;
    border-bottom: 0;
    // margin: 0 $lr;
  }
  .info-table-cell {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: $size6;
    color: #330e0e;
    text-align: center;
  }
  .td {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 4px;
    border-bottom: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    line-height: 18px;
  }

  .td1 {
    width: 40%;
    background: #f3f9ff;
    font-size: 14px;
    line-height: 20px;
  }
  .td2 {
    width: 20%;
  }
  .td3 {
    width: 20%;
  }

  .title {
    text-align: center;
    background: #f3f9ff;
    font-weight: 600;
    color: $gray1;
    font-size: 12px;
  }
  .info-table-content {
    display: inline-block;
  }
  .info-table-edit {
    font-size: 14px;
    font-weight: 400;
    color: #3f6efc;
    margin-left: 10px;
  }

  .down-icon {
    color: red;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
  }
  .down-icon {
    color: blue;
    font-size: 14px;
    font-weight: 500;
  }
  .icon-up {
    color: red;
    font-size: 16px;
  }
  .icon-down {
    color: #439eff;
    font-size: 16px;
  }
}
</style>
