import axios from 'axios';
import store from '@/store';
import { formatBlobToEext, resetToLogin, getCurToken } from '@/lib/utils';
import { Dialog } from 'vant';
import { wechatAppid } from '@/global';

// const isProd = process.env.NODE_ENV === 'production';

axios.defaults.baseURL = process.env.VUE_APP_PROXY_API_URL;
console.log('process.env.VUE_APP_PROXY_API_URL', process.env.VUE_APP_PROXY_API_URL);
const request = axios.create({
  headers: {
    post: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    'X-Requested-With': 'XMLHttpRequest',
    _client_wx: 1
  },
  timeout: 1000000,
  responseType: 'json'
});

// 请求发送前拦截器
request.interceptors.request.use(
  config => {
    const notLoading = ['/clinic/serviceOrder', '/getPatientRelByPhone'];
    if (!notLoading.some(url => config.url.includes(url))) {
      store.dispatch('addLoading');
    }

    config.headers = getHeaders(config.url);
    return config;
  },
  err => {
    console.error(err);
  }
);

// 请求返回时处理
request.interceptors.response.use(
  async res => {
    store.dispatch('subLoading');

    let resData = res.data;

    const directReturn = ['/export', '/wechat/signature'];
    if (directReturn.some(url => res.config.url.includes(url))) {
      return resData;
    }

    if (['blob'].includes(res.config.responseType)) {
      if (resData.type === 'application/json') {
        const blobRes = await formatBlobToEext(resData);
        if (blobRes) {
          resData = blobRes;
        } else {
          throw new Error('下载错误，请稍候再试');
        }
      } else {
        return resData;
      }
    }

    if (resData.status === 'ok') {
      return resData;
    }

    const { code, msg } = resData || {};

    if (code === 200) {
      return resData;
    } else if (code === 401 || msg === '诊所id不能为空') {
      store.dispatch('subLoading');
      Dialog.alert({
        title: '提示',
        message: '登录状态已过期'
      }).then(() => {
        resetToLogin();
      });
      setTimeout(() => {
        store.dispatch('subLoading');
      }, 2000);
    } else {
      store.dispatch('subLoading');
      setTimeout(() => {
        store.dispatch('subLoading');
      }, 2000);
      Dialog.alert({
        title: msg || '未知错误',
        message: ''
      }).then(() => {
        if (res.config.url.includes('getInfo')) {
          resetToLogin();
        }
      });
    }
    throw resData;
  },
  err => {
    if (err.response) {
      err.code = err.response.status;
      err.requestInfo = err.response;
    } else {
      err.code = '接口报错';
    }
    return Promise.reject(err);
  }
);

export default request;

// 以下接口未登录时不传token
const getHeaders = function(url) {
  const headers = {
    timestamp: new Date().valueOf(),
    'Content-Type': 'application/json;charset=utf-8',
    appId: wechatAppid
  };

  const normalHeaders = [
    'decodeRxNumber',
    'getWxUrlSchemeInfo',
    'accesskey',
    '/sms_code/',
    'login',
    'goods/findSkuById',
    'goods/detail/spec/?skuid',
    'goodsid/?promoteCode=',
    'goods/promotecode/',
    'openid/?code',
    'goods/list/'
  ];
  const token = getCurToken();

  if (!normalHeaders.some(i => url.includes(i)) && token) {
    headers.Authorization = token;
  }
  return headers;
};
