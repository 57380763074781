export const isDev = process.env.NODE_ENV === 'development';

export const userClient = ['clinic', 'partner', 'patient'];

export const isMaster =
  location.origin.indexOf('https://www.ajk.com') === 0 || location.origin.indexOf('https://ajk.com') === 0;

let appid = 'wx34e3db8542afcddb';

if (!isMaster) {
  appid = 'wx34e3db8542afcddb';
}
export const wechatAppid = appid;
export const clientPlatform = 'pay_platform_company';

export const adminAuth = [
  '18665612922', //健
  '15813306682', //勤
  '18924007877', //麦
  '18819446959', //明
  '18664529512' //峰
];

export const echartsPieColor = [
  '#527DFD',
  '#1DCDC2',
  '#FFCA19',
  '#7336F0',
  '#fc752d',
  '#81e600',
  '#10cc63',
  '#fa4f9b',
  '#df20f8',
  '#f6fc50',
  '#ff2d51',
  '#48f3ff',
  '#cbfc56',
  '#8468fc',
  '#faa014',
  '#ffb900',
  '#fed343',
  '#7336f0',
  '#824bf4',
  '#986af6',
  '#527DFD',
  '#1DCDC2',
  '#FFCA19',
  '#7336F0',
  '#fc752d',
  '#81e600',
  '#10cc63',
  '#fa4f9b',
  '#df20f8',
  '#f6fc50',
  '#ff2d51',
  '#48f3ff',
  '#cbfc56',
  '#8468fc',
  '#faa014',
  '#ffb900',
  '#fed343',
  '#7336f0',
  '#824bf4',
  '#986af6',
  '#527DFD',
  '#1DCDC2',
  '#FFCA19',
  '#7336F0',
  '#fc752d',
  '#81e600',
  '#10cc63',
  '#fa4f9b',
  '#df20f8',
  '#f6fc50',
  '#ff2d51',
  '#48f3ff',
  '#cbfc56',
  '#8468fc',
  '#faa014',
  '#ffb900',
  '#fed343',
  '#7336f0',
  '#824bf4',
  '#986af6'
];

export const isWeChat = window.navigator.userAgent.toLowerCase().includes('micromessenger');

export const isMiniProgram = /miniprogram|wechatdevtools/.test(window.navigator.userAgent.toLowerCase());

export const viewerOptions = {
  backdrop: true, // 是否启用播放模态背景[布尔值/字符串：默认true](不启用时，点击空白处不会退出播放，仅点击右上角关闭按钮才会退出播放)
  inline: false, // 启用inline模式[布尔值：默认false]
  play: false, // 启用inline模式[布尔值：默认false]
  playable: false, // 启用inline模式[布尔值：默认false]
  button: true, // 显示右上角关闭按钮[布尔值：默认true]
  navbar: true, // 显示缩略图导航[布尔值/数字：默认true]
  title: false, // 显示当前图片的标题(显示alt属性或从URL解析出的图片名称)[布尔值/数字/方法/数组：默认true]
  toolbar: true, // 显示工具栏、自定义工具栏[布尔值/数字/对象：默认true]
  tooltip: true, // 显示缩放百分比[布尔值：默认true]
  movable: true, // 图片是否可移动[布尔值：默认true]
  zoomable: true, // 图片是否可缩放[布尔值：默认true]
  rotatable: true, // 图片是否可旋转[布尔值：默认true]
  scalable: true, // 图片是否可翻转[布尔值：默认true]
  transition: true, // 使用CSS3过渡[布尔值：默认true]
  fullscreen: false, // 播放时是否全屏[布尔值/全屏配置：默认true]
  keyboard: true, // 是否支持键盘[布尔值：默认true]
  url: 'data-src' // 播放时图片地址 URL 来源。[字符串/方法：默认src](如果是字符串，则应该是每个图像元素的属性之一。 如果是方法，则应该返回一个有效的图像URL)
};

export const viewerExtensions = ['.jpg', '.png', '.jpeg'];

export const appConfig = {
  clinic: {
    appType: 'clinic',
    userType: '1',
    miniQrCode: '/img/i检医.jpg',
    appTitle: '爱检医'
  },
  partner: {
    appType: 'partner',
    userType: '2',
    miniQrCode: '/img/i检云.jpg',
    appTitle: '爱检云'
  },
  patient: {
    appType: 'patient',
    userType: '3',
    miniQrCode: '/img/i检康.jpg',
    appTitle: '爱检康'
  }
};

export const apiModes = {
  DM: 'DM',
  JY: 'JY',
  TK: 'TK'
};

export const paymentModeMap = {
  clinic: '1',
  patient: '2'
};
