<template>
  <div class="personal" v-if="doctorCurrent">
    <div class="personal-bg"></div>
    <div class="personal-header">
      <div class="personal-info" @click="toInfo">
        <van-image fit="cover" :src="doctorCurrent.avatar || avatar" class="avatar" />
        <div class="personal-right">
          <p class="personal-name">{{ !pageInit ? '去登录' : doctorCurrent.name }}</p>
          <!-- <p class="personal-tel">{{ !pageInit ? '请登陆后查看' : doctorCurrent.phone }}</p> -->
          <p class="personal-tel">{{ !pageInit ? '请登陆后查看' : $getGender(doctorCurrent.gender) }}</p>
        </div>
      </div>
    </div>
    <div class="personal-func-list">
      <cell v-for="(item, index) in funcList" :key="index" is-link @click="toLink(item.link)">
        <template #title>
          <span :class="['iconfont', `${item.icon}`]"></span>
          <span class="custom-title">{{ item.label }}</span>
        </template>
        <template #right-icon>
          <div class="right">
            <icon name="arrow" />
          </div>
        </template>
      </cell>
    </div>
    <div style="height:200px" />

    <opinion v-show="opinionModel.show" :show.sync="opinionModel.show" :info="opinionModel.info" />
    <tabbar :curIndex="2" />
  </div>
</template>

<script>
import { Cell, Icon, Image as VanImage } from 'vant';
import { mapGetters } from 'vuex';
import opinion from '@/components/opinion';
import tabbar from '../components/tabbar';
import { signOut, notRequest, routerPush, checkLogin } from '@/lib/utils';

export default {
  components: { opinion, tabbar, Cell, Icon, VanImage },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      opinionModel: {
        show: false,
        info: {}
      },
      funcList: [
        {
          label: '我的诊所',
          icon: 'icon-wdzs',
          link: '/clinic-info-edit'
        },
        {
          label: '我的订单',
          icon: 'icon-wddd',
          link: '/clinic-my-order'
        },
        {
          label: '我的医生',
          icon: 'icon-ys',
          link: '/clinic-my-doctor'
        },
        {
          label: '我的设备',
          icon: 'icon-wdsb',
          link: '/clinic-my-device'
        },
        {
          label: '我的库存',
          icon: 'icon-kcgl',
          link: '/clinic-inventory'
        },
        {
          label: '我的业务员',
          icon: 'icon-wdywy',
          link: '/clinic-my-partner'
        },
        {
          label: '收货人管理',
          icon: 'icon-sh',
          link: '/clinic-my-address'
        },
        {
          label: '意见反馈',
          icon: 'icon-kf',
          link: 'opinion'
        },
        {
          label: '密码设置',
          icon: 'icon-mmsz',
          link: '/clinic-set-password'
        },
        {
          label: '退出登录',
          icon: 'icon-tc',
          link: 'exit'
        }
      ],
      successImg: 'https://mms2.baidu.com/it/u=2864809941,757158551&fm=253&app=138&f=JPEG&fmt=auto&q=75?w=200&h=200'
    };
  },
  computed: {
    ...mapGetters(['clinicInfo', 'doctorCurrent']),
    pageInit() {
      return !notRequest();
    }
  },
  mounted() {
    if (!this.pageInit) return;

    this.$store.dispatch('set_doctorCurrent');
  },
  methods: {
    async toInfo() {
      await checkLogin();

      routerPush({
        name: 'clinicInfoEdit'
      });
    },
    async toLink(path) {
      await checkLogin();

      if (path === 'opinion') {
        this.opinionModel.show = true;
      } else if (path === 'exit') {
        signOut();
      } else {
        routerPush({
          path
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.personal {
  position: relative;
  padding-top: 40px;

  .personal-bg {
    background: $btnbg;
    height: 110px;
    border-radius: 0 0 $radius $radius;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .personal-header {
    margin: 0 $lr;
    background: #fff;
    border-radius: $radius;
    color: $black1;
    position: relative;

    .personal-info {
      // text-align: center;
      padding: $tb $lr;
      line-height: 30px;
      display: flex;

      .avatar {
        border-radius: 50%;
        width: 70px;
        height: 70px !important;
        border: 2px solid #fff;
        margin-right: $lr;
        overflow: hidden;
      }
      .personal-right {
        .personal-name {
          font-size: 20px;
          font-weight: 600;
          margin-top: 10px;
        }
        .personal-tel {
          font-size: $size4;
        }
      }
    }

    .personal-num {
      @include flex(row);
      justify-content: space-around;
      font-size: $size4;
      .personal-num-item {
        text-align: center;
        line-height: 26px;
        .num {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
